import React from 'react';

import Logo from './logo/Logo';
import Nav from './nav/Nav'
const Header = () => {
    return (
        <>
        <Logo/>
        <Nav/>
        </>
    )
}


export default Header